/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 500,
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
  },
  loadingRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function DialogContainer({
  open,
  handleClose,
  title,
  children,
  okBtnText,
  okBtnAction,
  closeOnPress,
  cancelOnPress,
  confirmLoading,
  cancelText,
  cancelDisable,
  okDisable,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.root }}
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">{title || 'Title'}</DialogTitle>
      <DialogContent>
        {confirmLoading ? (
          <div className={classes.loadingRow}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          children
        )}
      </DialogContent>
      {okBtnAction ? (
        <DialogActions>
          <Button
            onClick={cancelOnPress || handleClose}
            color="secondary"
            disabled={confirmLoading || cancelDisable}
          >
            {cancelText || '取消'}
          </Button>
          <Button
            onClick={okBtnAction || handleClose}
            color="primary"
            disabled={confirmLoading || okDisable}
          >
            {okBtnText || '確定'}
          </Button>
        </DialogActions>
      ) : null}
      {closeOnPress ? (
        <div className={classes.closeBtn} onClick={closeOnPress}>
          <CloseIcon />
        </div>
      ) : null}
    </Dialog>
  );
}

DialogContainer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  okBtnText: PropTypes.string,
  okBtnAction: PropTypes.func,
  closeOnPress: PropTypes.func,
  cancelOnPress: PropTypes.func,
  confirmLoading: PropTypes.bool,
  cancelText: PropTypes.string,
  cancelDisable: PropTypes.bool,
  okDisable: PropTypes.bool,
};
