import moment from 'moment';
import axios from '../service/axios';

export const refreshToken = () => axios.post('/user/refreshtoken');

export const getUserList = filter => axios.get('/user/', { params: filter });

export const getOneUserProfile = userid => axios.get(`/user/${userid}`);

export const userLogout = () => axios.post('/logout');

export const deleteOneUserProfile = userid => axios.delete(`/user/${userid}`);

export const getUserProfile = () => axios.get('/user/profile');

export const userpasswordLogin = para =>
  axios.post('/user/passwordLogin', para);

export const changeOneUserProfile = (userid, values) =>
  axios.patch(`/user/${userid}`, values);

export const resetOneUserPassword = (userid, password) =>
  axios.post(`/user/${userid}/resetpassword`, password);

export const passwordRegister = para =>
  axios.post('/user/passwordRegister', para);

export const createOneCompany = para => axios.post('/company', para);

export const getCompanyList = filter =>
  axios.get('/company', { params: filter });

export const getOneCompany = companyid => axios.get(`/company/${companyid}`);

export const deleteOneCompany = companyid =>
  axios.delete(`/company/${companyid}`);

export const changeOneCompanyInformation = (companyid, para) =>
  axios.patch(`/company/${companyid}`, para);

export const createOneProperty = para => axios.post('/property/', para);

export const updateAProperty = (propertyid, para) =>
  axios.patch(`/property/${propertyid}`, para);

export const getPropertyList = filter =>
  axios.get('/property/', { params: filter });

export const deleteOneProperty = propertyid =>
  axios.delete(`/property/${propertyid}`);

export const getAPropertyInformation = propertyid =>
  axios.get(`/property/${propertyid}`);

export const getCurrentRental = propertyid =>
  axios.get(`/property/${propertyid}/rental/current`);

export const getAllRental = (propertyid, filter) =>
  axios.get(`/property/${propertyid}/rental`, { params: filter });

export const createNewRental = (propertyid, para) =>
  axios.post(`/property/${propertyid}/rental`, para);

export const getARental = (propertyid, rentalid) =>
  axios.get(`/property/${propertyid}/rental/${rentalid}`);

export const updateARentalInformation = (propertyid, rentalid, para) =>
  axios.patch(`/property/${propertyid}/rental/${rentalid}`, para);

export const createARentalContact = (rentalid, para) =>
  axios.post(`/property/rental/${rentalid}/contact`, para);

export const getRentalContactList = (rentalid, filter) =>
  axios.get(`/property/rental/${rentalid}/contact`, { params: filter });

export const getDebitNote = filter =>
  axios.get('/debitnote', { params: filter });

export const createRentalContacts = (rentalid, contactsArray) => {
  const PromiseArray = [];
  contactsArray.forEach(contact => {
    PromiseArray.push(
      axios.post(`/property/rental/${rentalid}/contact`, contact),
    );
  });
  return Promise.all(PromiseArray);
};

export const updateARentalContact = (rentalid, contactid, para) =>
  axios.patch(`/property/rental/${rentalid}/contact/${contactid}`, para);

export const deleteARentalContact = (rentalid, contactid) =>
  axios.delete(`/property/rental/${rentalid}/contact/${contactid}`);

export const getOneDebitNote = debitnoteid =>
  axios.get(`/debitnote/${debitnoteid}`);

export const getDebitNoteRowsList = (debitnoteid, filter) =>
  axios.get(`/debitnote/${debitnoteid}/debitnoterow`, { params: filter });

export const createARowDebitNote = (debitnoteid, para) =>
  axios.post(`/debitnote/${debitnoteid}/debitnoterow`, para);

export const deleteOneRowDebitNote = (debitnoteid, debitnoterowid) =>
  axios.delete(`/debitnote/${debitnoteid}/debitnoterow/${debitnoterowid}`);

export const UpdateOneDebitNoteRow = (debitnoteid, debitnoterowid, para) =>
  axios.patch(`/debitnote/${debitnoteid}/debitnoterow/${debitnoterowid}`, para);

export const getPaymentListInDebitNote = debitnoteid =>
  axios.get(`/debitnote/${debitnoteid}/payment`);

export const createPaymentForDebitNote = (debitnoteid, para) =>
  axios.post(`/debitnote/${debitnoteid}/payment`, para);

export const deleteOnePaymentInDebitNote = (debitnoteid, paymentid) =>
  axios.delete(`/debitnote/${debitnoteid}/payment/${paymentid}`);

export const getOnePaymentidInDebitNote = (debitnoteid, paymentid) =>
  axios.get(`/debitnote/${debitnoteid}/payment/${paymentid}`);

export const updateOnePaymentidInDebitNote = (debitnoteid, paymentid, para) =>
  axios.patch(`/debitnote/${debitnoteid}/payment/${paymentid}`, para);

export const getUniqueBank = params => axios.get('/property/bank', { params });

export const UpdateOneDebitNote = (debitnoteid, para) =>
  axios.patch(`/debitnote/${debitnoteid}`, para);

export const uploadRawFile = file => axios.post('/file/raw/', file);

export const uploadARawFile = file => {
  const formData = new FormData();
  formData.append('file', file[0]);
  return axios.post('/file/raw/', formData);
};

export const addPropertiesToRental = (rentalid, propertyArray) => {
  const promiseArray = [];
  propertyArray.forEach(item => {
    promiseArray.push(
      axios.post(`/property/rental/${rentalid}/propertyrental`, {
        propertyid: item.propertyid,
      }),
    );
  });
  return Promise.all(promiseArray);
};

export const deletePropertiesFromRental = (rentalid, propertyArray) => {
  const promiseArray = [];
  propertyArray.forEach(item => {
    promiseArray.push(
      axios.delete(
        `/property/rental/${rentalid}/propertyrental/${item.propertyrentalid}`,
      ),
    );
  });
  return Promise.all(promiseArray);
};

export const deleteARental = (propertyid, rentalid) =>
  axios.delete(`/property/${propertyid}/rental/${rentalid}`);

export const generateDebitNoteByDayRange = nextMonth => {
  const para = {
    startDate: moment(nextMonth)
      .startOf('month')
      .toDate(),
    endDate: moment(nextMonth)
      .endOf('month')
      .toDate(),
  };
  return axios.post('/debitnote/forceGenerateAllDateRange', para);
};

export const createDebitNote = para => axios.post('/debitnote/', para);

export const deleteDebitNote = debitnoteid =>
  axios.delete(`/debitnote/${debitnoteid}`);

export const getMaintenanceList = filter =>
  axios.get('/maintenance', { params: filter });

export const createAMaintenance = para => axios.post('/maintenance', para);

export const updateAMaintenance = (maintenanceid, para) =>
  axios.patch(`/maintenance/${maintenanceid}`, para);

export const deleteAMaintenace = maintenanceid =>
  axios.delete(`/maintenance/${maintenanceid}`);

export const getAMaintenaceInfo = maintenanceid =>
  axios.get(`/maintenance/${maintenanceid}`);

export const getDebitNoteBalanceSummary = filter =>
  axios.get('/debitnote/summary', { params: filter });

export const forceGenerateNoteByDateRange = para =>
  axios.post('/debitnote/forceGenerateAllDateRange', para);

export const regenerateNoteByID = debitnoteid =>
  axios.post(`/debitnote/${debitnoteid}/regenerate`);

export const getUserLoginHistoryList = filter =>
  axios.get('/user/history', { params: filter });

export const getUserLoginHistoryListByID = (userid, filter) =>
  axios.get(`/user/${userid}/history`, { params: filter });
