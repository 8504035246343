/* eslint-disable import/no-unresolved */
import React, { Fragment, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import jwtDecode from 'jwt-decode';

// layout
// import Main from 'components/MainLayout/Main';

// pages
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import StaffPage from 'containers/StaffPage';
import CompanyPage from 'containers/CompanyPage';
import PropertyPage from 'containers/PropertyPage';
import TenantPage from 'containers/TenantPage';
import DebitNotePage from 'containers/DebitNotePage';
import Login from 'containers/LoginPage/Loadable';
import MaintenancePage from 'containers/MaintenancePage/Loadable';
import HistoryPage from 'containers/HistoryPage';

import PrivateRoute from '../../helpers/PrivateRoute';
import { AuthContext } from '../../context/authContext';
import GlobalStyle from '../../global-styles';

import { TOAST_TIME } from './constants';

import { refreshToken } from '../../utils/api';

export default function App() {
  let existingTokens = null;
  const localToken = localStorage.getItem('tokens');
  if (localToken && localToken !== 'undefined') {
    existingTokens = localToken;
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const setTokens = token => {
    localStorage.setItem('tokens', token);
    setAuthTokens(token);
  };

  useEffect(() => {
    // console.log(localToken)
    if (localToken && localToken !== 'undefined') {
      try {
        const { exp } = jwtDecode(localToken);
        if (Date.now() >= exp * 1000) {
          localStorage.removeItem('tokens');
        } else {
          refreshToken()
            .then(response => {
              localStorage.setItem('tokens', response.data.token);
            })
            .catch(console.log);
        }
      } catch (err) {
        localStorage.removeItem('tokens');
      }
      // eslint-disable-next-line no-console
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Fragment>
        <Switch>
          <Route component={Login} exact path="/login" />
          <PrivateRoute main exact path="/" component={Dashboard} />
          <PrivateRoute main path="/staff" component={StaffPage} />
          <PrivateRoute main path="/companies" component={CompanyPage} />
          <PrivateRoute main path="/properties" component={PropertyPage} />
          <PrivateRoute main path="/tenant" component={TenantPage} />
          <PrivateRoute main path="/debitNotes" component={DebitNotePage} />
          <PrivateRoute main path="/maintenance" component={MaintenancePage} />
          <PrivateRoute main path="/history" component={HistoryPage} />
          <Route component={NotFoundPage} />
        </Switch>
        <ToastContainer
          position="top-right"
          autoClose={TOAST_TIME}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <GlobalStyle />
      </Fragment>
    </AuthContext.Provider>
  );
}
