/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Property from './Property/Loadable';
import PropertyForm from './PropertyForm/Loadable';
import PropertyDetails from './PropertyDetailPage/Loadable';
import PropertyRentalForm from './PropertyRentalForm/Loadable';
import PropertyEditRentalForm from './PropertyEditRentalForm/Loadable';
import PropertyEditForm from './PropertyEditForm/Loadable';
import PropertyRenewRentalForm from './PropertyRenewRentalForm/Loadable';
import PropertyNewDebitNoteForm from './PropertyNewDebitNoteForm';
import PropertyOwnerForm from './PropertyOwnerForm';
import MaintenanceForm from '../MaintenancePage/MaintenanceForm/Loadable';
import MaintenanceEditForm from '../MaintenancePage/MaintenanceEditForm/Loadable';
import PropertyMaintenanceDetails from './PropertyMaintenanceDetails/Loadable';

function index() {
  return (
    <Switch>
      <Route exact path="/properties" component={Property} />
      <Route exact path="/properties/new" component={PropertyForm} />
      <Route
        exact
        path="/properties/edit/:propertyid"
        component={PropertyEditForm}
      />
      <Route
        exact
        path="/properties/:propertyid/details"
        component={PropertyDetails}
      />
      <Route
        exact
        path="/properties/:propertyid/:rentalid/newDebitNote"
        component={PropertyNewDebitNoteForm}
      />
      <Route
        exact
        path="/properties/:propertyid/details/newRental"
        component={PropertyRentalForm}
      />
      <Route
        exact
        path="/properties/:propertyid/details/editRental/:rentalid"
        component={PropertyEditRentalForm}
      />
      <Route
        exact
        path="/properties/:propertyid/details/renewRental/:rentalid"
        component={PropertyRenewRentalForm}
      />
      <Route
        exact
        path="/properties/edit/:propertyid/owner"
        component={PropertyOwnerForm}
      />
      <Route
        exact
        path="/properties/:propertyid/details/maintenance/new"
        component={MaintenanceForm}
      />
      <Route
        exact
        path="/properties/:propertyid/details/maintenance/:maintenanceid"
        component={PropertyMaintenanceDetails}
      />
      <Route
        exact
        path="/properties/:propertyid/details/maintenance/:maintenanceid/edit"
        component={MaintenanceEditForm}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default index;
