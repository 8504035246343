import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

import Input from '../FormInput';
import Select from '../Select';
import { UpdateOneDebitNote } from '../../../utils/api';

const FORM_ITEM = {
  billingDate: '',
  contactInformation: '',
  contactName: '',
  debitNoteNo: '',
  postalAddress: '',
  status: 'Pending',
};

export const STATUS = [
  { value: 'Pending', label: '等待' },
  { value: 'Reviewed', label: '已檢視' },
  { value: 'Mailed', label: '已寄出' },
  { value: 'Settled', label: '已付款' },
];

const Schema = Yup.object().shape({
  billingDate: Yup.string()
    .nullable()
    .matches(
      /^([0-2]\d|[3][0-1])\/([0]\d|[1][0-2])\/((19|20)\d{2})$/,
      '日期格式錯誤',
    )
    .required('必填項目'),
  contactInformation: Yup.string()
    .nullable()
    .max(100, '不可多於100字元')
    .required('必填項目'),
  debitNoteNo: Yup.string()
    .max(100, '不可多於30字元')
    .required('必填項目'),
  postalAddress: Yup.string()
    .nullable()
    .max(300, '不可多於300字元')
    .required('必填項目'),
  status: Yup.string()
    .oneOf(['Pending', 'Reviewed', 'Mailed', 'Settled'], '錯誤狀態類型')
    .required('必填項目'),
});

class DebitnoteEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { ...FORM_ITEM },
      loading: false,
      formLoading: true,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { debitNote } = this.props;
    if (debitNote) {
      const form = {
        billingDate: moment(debitNote.billingDate).format('DD/MM/YYYY'),
        contactInformation: debitNote.contactInformation,
        contactName: debitNote.contactName,
        debitNoteNo: debitNote.debitNoteNo,
        debitnoteid: debitNote.debitnoteid,
        postalAddress: debitNote.postalAddress,
        propertyid: debitNote.Rental.propertyid,
        rentalid: debitNote.rentalid,
        status: debitNote.status,
      };
      this.setState({ formLoading: false, form });
    }
  }

  async onSubmit(values) {
    const { saveOnPress } = this.props;
    this.setState({ loading: true });
    const para = {
      ...values,
      billingDate: moment(values.billingDate, 'DD/MM/YYYY').toISOString(),
    };
    try {
      await UpdateOneDebitNote(values.debitnoteid, para);
      this.setState({ loading: false }, () => saveOnPress());
      toast('編輯月結單資料成功。');
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
      toast('網絡異常，請重試。');
    }
  }

  renderEditDisable(values) {
    const { form } = this.state;
    const same = _.isEqual(values, form);
    if (same) {
      return true;
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    const { form, loading, formLoading } = this.state;
    if (formLoading) {
      return (
        <div className={classes.root}>
          <CircularProgress size={30} color="primary" />
        </div>
      );
    }
    return (
      <Formik
        initialValues={form}
        validationSchema={Schema}
        onSubmit={this.onSubmit}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form className={classes.root}>
            <div>
              <strong>請注意：</strong>
              是次編輯只會更改本月份之月結單資料。如發現物業或租戶資料有誤，請到
              <Link to={`/properties/${form.propertyid}/details`}>
                <strong>物業管理</strong>
              </Link>
              修改資料。
            </div>
            <div className={classes.formContainer}>
              <Select
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={e => {
                  setFieldValue('status', e.target.value);
                }}
                fieldName="status"
                label="月結單狀態"
                placeholder="月結單狀態"
                width="100%"
                options={STATUS}
              />
              <Input
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                fieldName="billingDate"
                label="發單日期"
                placeholder="DD/MM/YYYY"
                width="100%"
              />
              <Input
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                fieldName="debitNoteNo"
                label="付款通知書編號"
                placeholder="付款通知書編號"
                width="100%"
              />
              <Input
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                fieldName="contactInformation"
                label="主要聯絡人"
                placeholder="主要聯絡人"
                width="100%"
              />
              <Input
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                fieldName="postalAddress"
                label="信箱地址"
                placeholder="信箱地址"
                width="100%"
                textarea
              />
              <Button
                type="submit"
                style={{ width: '100%', marginTop: 20 }}
                variant="contained"
                color="primary"
                disabled={loading || this.renderEditDisable(values)}
              >
                <div>
                  {loading ? (
                    <CircularProgress color="primary" size={22} />
                  ) : (
                    '提交'
                  )}
                </div>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  gridRowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    // height: 500,
    margin: 20,
  },
  fieldCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerTextRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginBottom: 20,
    marginTop: 10,
    width: 640,
  },
  bubbleWrapper: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: '#ddd',
  },
  bubbleText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
  },
  headerText: {
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginLeft: 20,
  },
  addressCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  addressLink: {
    fontSize: 14,
    color: '#2e2e2e',
    fontWeight: 700,
  },
  addressTime: {
    fontSize: 16,
    color: 'rgba(51,51,51,0.6)',
    fontWeight: 500,
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    backgroundColor: '#2478FF',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 20,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(36, 120, 255, 0.8)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    width: '100%',
    backgroundColor: '#e74c3c',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 24,
    height: 48,
    '&:hover': {
      backgroundColor: '#c0392b',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  btn: {
    width: '100%',
  },
  dropZone: {
    width: '100%',
    height: '100%',
    border: '1px dotted #DEDEDE',
    borderRadius: 5,
    backgroundColor: '#F9F9FB',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '25px 10px',
    boxSizing: 'border-box',
  },
});

DebitnoteEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  debitNote: PropTypes.object.isRequired,
  saveOnPress: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(DebitnoteEditForm));
