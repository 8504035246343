import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Main from 'components/MainLayout/Main';
import { useAuth } from '../../context/authContext';

const PrivateRoute = ({ component: Component, main, ...rest }) => {
  const { authTokens } = useAuth();
  if (main) {
    return (
      <Route
        {...rest}
        render={({ location, ...props }) =>
          authTokens ? (
            <Main>
              <Component {...props} />
            </Main>
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { referer: location } }}
            />
          )
        }
      />
    );
  }
  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        authTokens ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { referer: location } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  main: PropTypes.bool.isRequired,
};

export default PrivateRoute;
