/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import DebitNote from './DebitNote/Loadable';
import DebitNoteForm from './DebitNoteForm/Loadable';
import DebitNotePayment from './DebitNotePayment/Loadable';
import DebitNotePaymentForm from './DebitNotePaymentForm/Loadable';

function index() {
  return (
    <Switch>
      <Route exact path="/debitNotes" component={DebitNote} />
      <Route exact path="/debitNotes/edit/:noteid" component={DebitNoteForm} />
      <Route
        exact
        path="/debitNotes/payment/:noteid"
        component={DebitNotePayment}
      />
      <Route
        exact
        path="/debitNotes/payment/newPayment/:noteid"
        component={DebitNotePaymentForm}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default index;
