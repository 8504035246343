import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuItem: {
    width: '100%',
    padding: '8px 16px',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    overflow: 'hidden',
  },
  iconContainer: {
    width: 56,
    minWidth: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  labelText: {
    minWidth: 0,
    marginTop: 4,
    marginBottom: 4,
    fontSize: 16,
  },
}));

function MenuItemButton({ icon, label }) {
  const classes = useStyles();
  return (
    <li className={classes.menuItem}>
      <div className={classes.itemRow}>
        <div className={classes.iconContainer}>{icon || null}</div>
        <div className={classes.labelText}>{label || ''}</div>
      </div>
    </li>
  );
}

MenuItemButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

export default MenuItemButton;
