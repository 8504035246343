/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 15,
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    marginRight: 20,
    minWidth: 150,
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
  },
}));

function index({ label, text }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.label}>{label || ''}:</div>
      <div className={classes.text}>{text || ''}</div>
    </div>
  );
}

index.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default index;
