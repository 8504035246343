/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import MenuItems from './MenuItems';
import { useAuth } from '../../context/authContext';
import { userLogout } from '../../utils/api';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a href="https://appicidea.com" target="_blank">
        AppicIDEA
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    // backgroundColor: 'pink',
    overflow: 'hidden',
  },
  contentContainer: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: 'unset !important',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  appLogo: {
    width: 160,
    height: 48,
    objectFit: 'contain',
  },
  listRoot: {
    // textDecoration: 'none',
    // color: 'rgba(0, 0, 0, 0.87)',
  },
  links: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  aciveLinks: {
    backgroundColor: 'rgba(63, 81, 181, 0.1)',
    width: '100%',
    height: '100%',
  },
  footer: {
    height: 54,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderTop: '1px solid #eee',
  },
  mainContent: {
    height: 'calc(100vh - 64px - 54px)',
    backgroundColor: '#f4f6f8',
  },
  headerBtn: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  bacckButton: {
    fontSize: 16,
    fontWeight: 500,
    color: 'white',
  },
}));

function Main(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { setAuthTokens } = useAuth();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const companyLogo = require('../../assets/svg/logo.svg');
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const history = useHistory();
  const logoutOnClick = async () => {
    try {
      await userLogout();
      setAuthTokens();
      localStorage.removeItem('tokens');
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.headerBtn}>
            {history.location.pathname.split('/').length - 1 > 1 ? (
              <Button
                className={classes.bacckButton}
                startIcon={<ArrowLeftIcon />}
                onClick={history.goBack}
              >
                上一頁
              </Button>
            ) : null}
          </div>
          <Button
            className={classes.bacckButton}
            endIcon={<ExitToAppIcon />}
            onClick={logoutOnClick}
          >
            登出
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img
            src={companyLogo}
            alt="appicidea logo"
            className={classes.appLogo}
          />
          <IconButton onClick={handleDrawerClose}>
            <MenuOpenIcon />
          </IconButton>
        </div>
        <Divider />
        <MenuItems />
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.contentContainer}>
          <div className={classes.mainContent}>{props.children}</div>
          <div className={classes.footer}>
            <Copyright />
          </div>
        </div>
      </main>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
