/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

import PropertyNewDebitNoteForm from '../../../components/Forms/PropertyNewDebitNoteForm';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
}));

function index() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>新增月結單</title>
      </Helmet>
      <div className={classes.paper}>
        <PropertyNewDebitNoteForm />
      </div>
    </div>
  );
}

export default index;
