import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Company from './Company/Loadable';
import CompanyForm from './CompanyForm/Loadable';
import CompanyEditForm from './CompanyEditForm/Loadable';

function index() {
  return (
    <Switch>
      <Route exact path="/companies" component={Company} />
      <Route exact path="/companies/new" component={CompanyForm} />
      <Route
        exact
        path="/companies/edit/:companyid"
        component={CompanyEditForm}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default index;
