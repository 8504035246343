/* eslint-disable indent */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    // height: 95,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: 8,
    borderRadius: 6,
    minHeight: 38,
    fontSize: 14,
    fontWeight: 500,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:focus': {
      outline: 'none',
    },
  },
  optionSubText: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

function SectionTitleAutoComplete({
  options,
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
}) {
  const classes = useStyles();
  const onChange = useCallback(
    (event, input) => {
      if (!input) {
        setFieldValue('title', '');
        return;
      }
      const value = input && input.value ? input.value : input;
      setFieldValue('title', value);
    },
    [setFieldValue],
  );
  const renderInput = useCallback(
    params => (
      <div ref={params.InputProps.ref} style={{ width: '100%' }}>
        <input
          {...params.inputProps}
          placeholder="項目名稱"
          name="title"
          className={classes.input}
          value={values.title}
        />
      </div>
    ),
    [values],
  );
  return (
    <div className={classes.root}>
      <div className={classes.label}>結單分段標題</div>
      <Autocomplete
        freeSolo
        options={options}
        onChange={onChange}
        clearOnBlur={false}
        getOptionLabel={option => option.title}
        onBlur={handleBlur}
        style={{ width: '100%' }}
        renderInput={renderInput}
        onInputChange={(event, value) => setFieldValue('title', value)}
        renderOption={option => (
          <React.Fragment>
            <div>
              <strong>{option.title}</strong>
            </div>
          </React.Fragment>
        )}
      />
      {errors && touched && errors.title && touched.title ? (
        <div className={classes.errmsg}>{errors.title}</div>
      ) : null}
    </div>
  );
}

SectionTitleAutoComplete.propTypes = {
  options: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default SectionTitleAutoComplete;
