import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 95,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
  },
}));

const Index = ({
  label,
  fieldName,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  options,
  width,
  placeholder,
}) => {
  const BootstrapInput = withStyles(() => ({
    root: {
      width,
    },
    input: {
      height: 40,
      width: '100%',
      minWidth: 146,
      padding: '10px 18px',
      borderRadius: 5,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      boxSizing: 'border-box',
      '&:focus': {
        outline: 'none',
        borderRadius: 5,
      },
    },
  }))(InputBase);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <Select
        name={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        input={<BootstrapInput />}
      >
        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {errors && touched && errors[fieldName] && touched[fieldName] ? (
        <div className={classes.errmsg}>{errors[fieldName]}</div>
      ) : null}
    </div>
  );
};

const styles = () => ({
  root: {
    width: '100%',
    height: 72,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 12,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '0 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
});

Index.defaultProps = {
  width: '280px',
  placeholder: '',
};

Index.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  width: PropTypes.string,
  placeholder: PropTypes.string,
};

export default withStyles(styles)(Index);
