import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage, FastField } from 'formik';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
  descriptionText: {
    fontSize: 12,
    padding: '5px 0 5px 0',
    color: 'rgba(51,51,51,0.6)',
    width: '100%',
    textAlign: 'left',
  },
  input: {
    padding: '10px 18px',
    borderRadius: 6,
    // boxSizing: 'border-box',
    minHeight: 40,
    fontSize: 14,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const HookedFormInput = ({
  width,
  label,
  textarea,
  disableAutoComplete,
  descriptionText,
  name,
  type,
  step,
}) => {
  const classes = useStyles();
  const setting = {};
  if (step) {
    setting.step = step;
  }
  return (
    <div
      className={classes.root}
      style={
        textarea
          ? { width, minHeight: 100, marginBottom: 21 }
          : { width, height: 95 }
      }
    >
      <div className={classes.label}>{label}</div>
      <FastField
        name={name}
        className={classes.input}
        style={{ minHeight: textarea ? 100 : 40, width }}
        autoComplete={disableAutoComplete ? 'new-password' : null}
        component={textarea ? 'textarea' : 'input'}
        type={type}
        {...setting}
      />
      {descriptionText ? (
        <div className={classes.descriptionText}>{descriptionText}</div>
      ) : null}
      <ErrorMessage
        name={name}
        render={msg => <div className={classes.errmsg}>{msg}</div>}
      />
    </div>
  );
};

HookedFormInput.defaultProps = {
  width: 280,
  label: 'Input Title',
  textarea: false,
  type: 'text',
};

HookedFormInput.propTypes = {
  type: PropTypes.string,
  width: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  textarea: PropTypes.bool,
  disableAutoComplete: PropTypes.bool,
  descriptionText: PropTypes.string,
  step: PropTypes.string,
};

export default HookedFormInput;
