/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-multi-comp */
/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// table
import TableContainer from '@material-ui/core/TableContainer';

import {
  getOneDebitNote,
  getAPropertyInformation,
  getOneCompany,
  createARowDebitNote,
  getARental,
  getRentalContactList,
  createDebitNote,
} from '../../../utils/api';
import { numberWithCommas } from '../../../utils/common';
import DialogContainer from '../../Dialog/DialogContainer';
import DebitnoteEditForm from '../DebitnoteEditForm';
import PaymentItemAutoComplete from '../PaymentItemAutoComplete';
import AddSectionForm from '../AddSectionForm';
import Input from "../FormInput";
import InfoRow from '../InfoRow';
import DatePickerInput from '../DatePickerInput';

const ITEMS = [
  { value: 'Rent', title: 'Rent' },
  { value: 'Rent (inclusive Management Fee & Rates)', title: 'Rent (inclusive Management Fee & Rates)' },
  { value: 'License fee', title: 'License fee' },
  { value: 'Management fee', title: 'Management fee' },
  { value: 'Rates', title: 'Rates' },
  { value: 'Less: Rates Concession', title: 'Less: Rates Concession' },
  { value: 'Government Rent', title: 'Government Rent' },
]

const schemaTemp = Yup.object().shape({
  startDate: Yup.string()
    .nullable()
    .required('必填項目'),
  endDate: Yup.string()
    .nullable()
    .required('必填項目'),
  amount: Yup.number()
    .nullable()
    .test(
      'two-decimal',
      '最多輸入兩位小數',
      value => {
        if (value) {
          return `${value}`.match(/^-?(?!0\d|$)\d*(\.\d{1,2})?$/)
        }
        return true;
      },
    )
    .required('必填項目'),
  itemName: Yup.string()
    .max(100, '款項詳情不可多於100字')
    .required('必填項目'),
  remark: Yup.string()
    .nullable()
    .max(120, '款項詳情不可多於120字'),
});

const ROW_ITEM = {
  amount: null,
  startDate: moment().startOf('month').toISOString(),
  endDate: moment().endOf('month').toISOString(),
  focus: null,
  itemName: '',
  remark: '',
  sectionId: 1,
  sectionName: "Current Amount",
}

const FORM_ITEM = {
  propertyid: null,
  rentalid: null,
  billingDate: new Date(),
  status: 'Pending',
  address: '',
  bankName: '',
  bankACNo: '',
  bankCompanyName: '',
  debitNoteNo: '',
  contactName: '',
  postalAddress: '',
  contactInformation: '',
  prevMonthAmount: 0,
  currentAmount: 0,
  paidAmount: 0,
  rows: {
    1: [{ ...ROW_ITEM }],
  },
}

class DebitNoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      formLoading: true,
      debitNote: {},
      company: {},
      loading: false,
      noteEditVisible: false,
      sectionVisible: false,
      editSectionVisible: false,
      deleteSectionVisible: false,
      selectedItem: {},
      rental: {},
    };
    this.noteSaveOnPress = this.noteSaveOnPress.bind(this);
  }

  componentDidMount() {
    this.initDebitNoteForm();
  }

  async initDebitNoteForm() {
    const { match } = this.props;
    const { params } = match;
    const { propertyid, rentalid } = params;
    const form = { ...FORM_ITEM };
    try {
      const propertyRes = await getAPropertyInformation(propertyid);
      const rentalRes = await getARental(propertyid, rentalid);
      const companyRes = await getOneCompany(propertyRes.data.companyid);
      const contactRes = await getRentalContactList(rentalid, { limit: 20, offset: 0 });
      const { data } = propertyRes;
      const { address, bankACNo, bankCompanyName, bankName } = data;
      form.propertyid = propertyid;
      form.rentalid = rentalid;
      form.address = address;
      form.bankName = bankName;
      form.bankACNo = bankACNo;
      form.bankCompanyName = bankCompanyName;
      form.address = rentalRes.data.address;
      form.contactName = rentalRes.data.rentalName;
      form.postalAddress = rentalRes.data.postalAddress;
      let contactInformation = '';
      contactRes.data.rows.forEach((contact, index) => {
        if (contact.type === 'debitnote') {
          if (index > 0) {
            contactInformation += '/'
          }
          contactInformation += `${contact.mobile}(${contact.contactName})`
        }
      })
      form.contactInformation = contactInformation;
      this.setState({
        formLoading: false,
        form,
        company: companyRes.data,
        rental: rentalRes.data,
      });
    } catch (err) {
      toast('網絡異常，請重試。');
      this.setState({ formLoading: false });
    }
  }

  async noteSaveOnPress () {
    const { match } = this.props;
    const { params } = match;
    const { noteid } = params;
    try {
      const noteRes = await getOneDebitNote(noteid);
      this.setState({ debitNote: noteRes.data, noteEditVisible: false });
    } catch (err) {
      toast('網絡異常，請重試。');
    }
  }

  filterNoteRows(rows) {
    const tempRows = [];
    rows.forEach(row => {
      tempRows.push({
        amount: row.amount,
        debitnoteid: row.debitnoteid,
        debitnoterowid: row.debitnoterowid,
        endDate: row.endDate,
        itemName: row.itemName,
        paidAmount: row.paidAmount,
        remark: row.remark || '',
        sectionId: row.sectionId,
        sectionName: row.sectionName,
        startDate: row.startDate,
      })
    })
    return tempRows;
  }

  async onSubmit(values) {
    const { rental } = this.state;
    const { history } = this.props;
    this.setState({ loading: true });
    const ROWS = [];
    Object.keys(values.rows).forEach(key => {
      values.rows[key].forEach(item => {
        if (!item.delete) {
          ROWS.push({ ...item });
        }
      })
    })
    const para = {
      ...values,
      prevMonthAmount: this.renderPrevAmount(ROWS) || 0,
      currentAmount: this.renderNoteTotalAmount(ROWS) || 0,
    };
    delete para.rows;
    const promiseArray = [];
    const newBillingDate = moment(values.billingDate).set('date', rental.payDate || moment().format('DD'));
    para.billingDate = newBillingDate.toDate();
    try {
      const res = await createDebitNote(para);
      ROWS.forEach(row => {
        promiseArray.push(createARowDebitNote(res.data.debitnoteid, row))
      })
      await Promise.all(promiseArray);
      this.setState({ loading: false }, () => history.goBack());
      toast('保存成功');
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
      toast('網絡異常，請重試。');
    }
  }

  renderNoteTotalAmount(rowArray) {
    const amount = _.sumBy(rowArray, row => {
      if (!row.delete) {
        return row.amount;
      }
      return 0;
    });
    return amount;
  };

  renderPrevAmount(rowArray) {
    const amount = _.sumBy(rowArray, row => {
      if (!row.delete && row.sectionName !== 'Current Amount') {
        return row.amount;
      }
      return 0;
    });
    return amount;
  };

  renderMonth(values, key) {
    if (key === 'others') {
      const dates = [];
      values[key].forEach(row => {
        dates.push(new Date(row.startDate));
      })
      const minDate = moment(Math.min.apply(null, dates)).format('M');
      return `${minDate}月或以前`;
    }
    if (key === 'current') {
      return '本月';
    }
    if (values[key].length > 0) {
      const month = moment(values[key][0].startDate).format('M');
      return `${month}月`;
    }
    return null;
  }

  renderTotalAmount(values) {
    let rows = [];
    Object.keys(values.rows).forEach(key => {
      rows = rows.concat(values.rows[key]);
    })
    const amount = _.sumBy(rows, row => {
      if (!row.delete && row.amount !== '') {
        return row.amount;
      }
      return 0;
    });
    return `HKD ${numberWithCommas(amount)}`;
  }

  renderSubTottal(values, key) {
    const rows = [...values.rows[key]];
    const amount = _.sumBy(rows, row => {
      if (!row.delete && row.amount !== '') {
        return row.amount
      }
      return 0;
    });
    return `HKD ${numberWithCommas(amount)}`;
  }

  renderDeleteDisable(values, key) {
    const rows = [...values.rows[key]];
    let count = 0;
    rows.forEach(row => {
      if (row.delete) {
        count += 1;
      }
    })
    if (rows.length - count <= 1) {
      return true;
    }
    return false;
  }

  renderStatusContainer() {
    const { debitNote } = this.state;
    const { classes } = this.props;
    switch (debitNote.status) {
      case 'Pending':
        return <Chip label="等待" variant="outlined" />;
      case 'Reviewed':
        return (
          <Chip
            label="已檢視"
            variant="outlined"
            className={classes.tealChip}
          />
        );
      case 'Mailed':
        return (
          <Chip
            label="已寄出"
            variant="outlined"
            className={classes.amberChip}
          />
        );
      case 'Settled':
        return <Chip label="已付款" variant="outlined" color="primary" />;
      default:
        return debitNote.status;
    }
  }

  renderAddress(property) {
    let address = '';
    address += property.streetName ? `${property.streetName} ` : '';
    address += property.buildingName ? `${property.buildingName} ` : '';
    address += property.shop ? `${property.shop} ` : '';
    return address;
  }

  renderSchema() {
    const { form } = this.state;
    const shemaObject = {};
    const keys = Object.keys(form.rows);
    keys.forEach(key => {
      shemaObject[key] = Yup.array().of(schemaTemp);
    });

    const schema = Yup.object().shape({
      address: Yup.string()
        .nullable()
        .max(400, '地址不可多於400字')
        .required('必填項目'),
      bankName: Yup.string()
        .max(100, '銀行名稱不可多於100字元')
        .nullable()
        .required('必填項目'),
      bankACNo: Yup.string()
        .max(30, '銀行帳戶號碼不可多於30字元')
        .nullable()
        .required('必填項目'),
      bankCompanyName: Yup.string()
        .max(50, '銀行戶口名稱不可多於50字元')
        .nullable()
        .required('必填項目'),
      debitNoteNo: Yup.string()
        .max(30, '月結單編號長度不可多於30字元')
        .nullable()
        .required('必填項目'),
      contactName: Yup.string()
        .max(30, '租戶長度不可多於30字元')
        .nullable()
        .required('必填項目'),
      postalAddress: Yup.string()
        .nullable()
        .max(400, '信箱地址不可多於400字')
        .required('必填項目'),
      contactInformation: Yup.string()
        .max(200, '租戶聯絡人長度不可多於200字元')
        .nullable()
        .required('必填項目'),
      prevMonthAmount: Yup.number()
        .nullable()
        .required('必填項目'),
      currentAmount: Yup.number()
        .nullable()
        .required('必填項目'),
      paidAmount: Yup.number().nullable(),
      rows: Yup.object().shape(shemaObject),
    });
    return schema;
  }

  render() {
    const { classes } = this.props;
    const {
      form,
      formLoading,
      debitNote,
      company,
      loading,
      noteEditVisible,
      sectionVisible,
      editSectionVisible,
      deleteSectionVisible,
      selectedItem,
    } = this.state;
    if (formLoading) {
      return (
        <div className={classes.root}>
          <CircularProgress size={30} color="primary" />
        </div>
      );
    }
    return (
      <Formik
        initialValues={form}
        validationSchema={this.renderSchema()}
        onSubmit={values => this.onSubmit(values)}
      >
        {({ values, setFieldValue, errors, touched, handleBlur, handleChange }) => {
          const addNewRecord = key => {
            const ROWS = { ...values.rows };
            const temp = ROWS[key];
            // const temp = [...values.rows[type]];
            temp.push({
              startDate: temp.length > 0 ? temp[0].startDate : null,
              endDate: temp.length > 0 ? temp[0].endDate : null,
              focus: null,
              amount: null,
              itemName: '',
              remark: '',
              debitnoteid: debitNote.debitnoteid,
              sectionId: temp.length > 0 ? temp[0].sectionId : key,
              sectionName: temp.length > 0 ? temp[0].sectionName : key,
            });
            setFieldValue(`rows`, ROWS);
          };
          const addNewSection = (title) => {
            const tempForm = { ...form }
            const ROWS = { ...values.rows };
            const index = Object.keys(values.rows).length + 1;
            const temp = [];
            temp.push({
              startDate: null,
              endDate: null,
              focus: null,
              amount: null,
              itemName: '',
              remark: '',
              debitnoteid: debitNote.debitnoteid,
              sectionId: index,
              sectionName: title,
            });
            ROWS[index] = temp;
            tempForm.rows = { ...ROWS };
            setFieldValue(`rows`, ROWS);
            this.setState({ form: tempForm });
          }
          const editSection = (title) => {
            const temp = [...values[selectedItem]];
            temp.forEach(item => {
              const section = item;
              section.sectionName = title;
            });
            setFieldValue(`${selectedItem}`, temp);
            this.setState({ selectedItem: {} });
          }
          const deleteSection = () => {
            const tempForm = { ...form };
            const temp = [...values.rows[selectedItem]];
            temp.forEach(item => {
              const section = item;
              section.delete = true;
            });
            delete tempForm.rows[selectedItem];
            setFieldValue(`rows.${selectedItem}`, temp);
            this.setState({ selectedItem: {}, form: tempForm });
          }
          const sectionOnSwapTop = (index) => {
            const temp = { ...values.rows };
            const keys = Object.keys(temp);
            const beingSwapedValue = [...values.rows[keys[index - 1]]];
            beingSwapedValue.forEach(item => {
              const section = item;
              section.sectionId = parseInt(keys[index], 10);
            });
            const swapValue = [...values.rows[keys[index]]];
            swapValue.forEach(item => {
              const section = item;
              section.sectionId = parseInt(keys[index - 1], 10);
            });
            setFieldValue(`rows.${keys[index - 1]}`, swapValue);
            setFieldValue(`rows.${keys[index]}`, beingSwapedValue);
          }
          const sectionOnSwapDown = (index) => {
            const temp = { ...values.rows };
            const keys = Object.keys(temp);
            const beingSwapedValue = [...values.rows[keys[index + 1]]];
            beingSwapedValue.forEach(item => {
              const section = item;
              section.sectionId = parseInt(keys[index], 10);
            });
            const swapValue = [...values.rows[keys[index]]];
            swapValue.forEach(item => {
              const section = item;
              section.sectionId = parseInt(keys[index + 1], 10);
            });
            setFieldValue(`rows.${keys[index + 1]}`, swapValue);
            setFieldValue(`rows.${keys[index]}`, beingSwapedValue);
          }
          return (
            <Form className={classes.root}>
              <Container maxWidth="xl">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.formContainer}>
                      <div className={classes.formWrapper}>
                        <Paper className={classes.infoContainer}>
                          <div className={classes.row}>
                            <div
                              className={classes.titleH2}
                              style={debitNote && debitNote.User ? null : (
                                { marginBottom: 16 }
                              )}
                            >
                              新增月結單
                            </div>
                            <div className={classes.previewRow}>
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                className={classes.button}
                                startIcon={<SaveIcon />}
                                disabled={loading}
                              >
                                <div>
                                  {loading ? (
                                    <CircularProgress color="primary" size={22} />
                                  ) : (
                                    '保存月結單'
                                  )}
                                </div>
                              </Button>
                            </div>
                          </div>
                          <InfoRow
                            label="物業所屬公司"
                            text={`${company.nameCn} ${company.name}`}
                          />
                          <div className={classes.seperator} />

                          <Input
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldName="debitNoteNo"
                            label="付款通知書編號"
                            placeholder="付款通知書編號"
                            width="100%"
                          />
                          <Input
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldName="contactName"
                            label="租戶"
                            placeholder="租戶"
                            width="100%"
                          />
                          <Input
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldName="address"
                            label="物業地址"
                            placeholder="物業地址"
                            width="100%"
                            textarea
                          />
                          <Input
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldName="postalAddress"
                            label="信箱地址"
                            placeholder="信箱地址"
                            width="100%"
                            textarea
                          />
                          <Input
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldName="contactInformation"
                            label="租戶聯絡人"
                            placeholder="租戶聯絡人"
                            width="100%"
                          />
                          <DatePickerInput
                            label="發單日期"
                            name="billingDate"
                            dateFormat="MM/yyyy"
                            showYearDropdown
                            isClearable
                            showMonthYearPicker
                          />
                          <Input
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldName="bankCompanyName"
                            label="銀行戶口名稱"
                            placeholder="銀行戶口名稱"
                            width="100%"
                          />
                          <Input
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldName="bankName"
                            label="銀行名稱"
                            placeholder="銀行名稱"
                            width="100%"
                          />
                          <Input
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            fieldName="bankACNo"
                            label="銀行戶口號碼"
                            placeholder="銀行戶口號碼"
                            width="100%"
                          />


                        </Paper>
                        <div className={classes.buttonRow}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<AddIcon />}
                            type="button"
                            onClick={() => this.setState({ sectionVisible: true })}
                            disabled={loading}
                          >
                            新增結單分段
                          </Button>
                        </div>
                        {Object.keys(values.rows).map((itemKey, keyIndex) => {
                          if (values.rows[itemKey].length < 1) {
                            return null;
                          }
                          const itemLength = values.rows[itemKey].length;
                          let deleteItem = 0;
                          values.rows[itemKey].forEach(rowItem => {
                            if (rowItem.delete) {
                              deleteItem += 1;
                            }
                          })
                          if (deleteItem === itemLength) {
                            return null;
                          }
                          return (
                            <TableContainer
                              key={`table_${keyIndex}`}
                              component={Paper}
                              style={{ overflowX: 'unset', marginBottom: 32 }} // canlender overflow
                            >
                              <div>
                                <Button
                                  size="small"
                                  startIcon={<ArrowDropUpIcon />}
                                  disabled={keyIndex === 0}
                                  onClick={() => sectionOnSwapTop(keyIndex)}
                                >
                                  上移
                                </Button>
                              </div>
                              <div className={classes.tableHeaderRow}>
                                <div className={classes.titleRow}>
                                  <div className={classes.tableHeaderText}>
                                    {values.rows[itemKey][0].sectionName || 'Non-titled'}
                                  </div>
                                  {values.rows[itemKey][0].sectionName === 'Current Amount' ? null : (
                                    <IconButton
                                      style={{ marginLeft: 10 }}
                                      size="small"
                                      onClick={() => this.setState({ editSectionVisible: true, selectedItem: itemKey })}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  )}
                                  {values.rows[itemKey][0].sectionName === 'Current Amount' ? null : (
                                    <IconButton
                                      size="small"
                                      onClick={() => this.setState({ deleteSectionVisible: true, selectedItem: itemKey })}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </div>
                                <div>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    type="button"
                                    onClick={() => addNewRecord(itemKey, keyIndex)}
                                    disabled={loading}
                                  >
                                    新增紀錄
                                  </Button>
                                </div>
                              </div>
                              <div className={classes.TableCol}>
                                <div className={classes.headerRow}>
                                  <div className={classes.headerCell} style={{ width: 250 }}>日期</div>
                                  <div className={classes.headerCell} style={{ flex: 2 }}>款項詳情</div>
                                  <div className={classes.headerCell} style={{ flex: 1 }}>金額(HKD)</div>
                                  <div className={classes.funcCol} />
                                </div>
                                <div className={classes.tableBody}>
                                  {values.rows[itemKey].map((row, index) => {
                                    if (row.delete) {
                                      return null;
                                    }
                                    return (
                                      <div className={classes.tableBodyCol} key={row.index}>
                                        <div className={classes.tableBodyRow}>
                                          <div className={classes.dateCell}>
                                            <DateRangePicker
                                              readOnly
                                              openDirection="up"
                                              startDate={
                                                row.startDate
                                                  ? moment(row.startDate)
                                                  : null
                                              }
                                              startDateId={`date_due1_start_${itemKey}_${index}`}
                                              endDate={
                                                row.endDate
                                                  ? moment(row.endDate)
                                                  : null
                                              }
                                              endDateId={`date_due1_end_${itemKey}_${index}`}
                                              onDatesChange={({
                                                startDate,
                                                endDate,
                                              }) => {
                                                if (startDate) {
                                                  setFieldValue(
                                                    `rows.${itemKey}[${index}].startDate`,
                                                    startDate.toISOString(),
                                                  );
                                                }
                                                if (endDate) {
                                                  setFieldValue(
                                                    `rows.${itemKey}[${index}].endDate`,
                                                    endDate.toISOString(),
                                                  );
                                                }
                                              }}
                                              startDatePlaceholderText="起始日期"
                                              endDatePlaceholderText="結束日期"
                                              monthFormat="YYYY[年]MM[月]"
                                              focusedInput={row.focus}
                                              onFocusChange={focusedInput => {
                                                setFieldValue(
                                                  `rows.${itemKey}[${index}].focus`,
                                                  focusedInput,
                                                );
                                              }}
                                              isOutsideRange={() => false}
                                              // showClearDates
                                              phrases={{
                                                closeDatePicker: '關閉',
                                                clearDates: '清除日期',
                                              }}
                                              small
                                              displayFormat={() => 'DD/MM/YYYY'}
                                            />
                                            <ErrorMessage
                                              name={`${itemKey}[${index}].endDate`}
                                              component='div'
                                              className={classes.errorMsg}
                                            />
                                          </div>
                                          <div className={classes.tableCell} style={{ flex: 2 }}>
                                            <PaymentItemAutoComplete
                                              values={values.rows}
                                              options={ITEMS}
                                              errors={errors}
                                              touched={touched}
                                              setFieldValue={(field, value) => {
                                                setFieldValue(`rows.${itemKey}[${index}].itemName`, value);
                                              }}
                                              handleBlur={handleBlur}
                                              index={index}
                                              itemKey={itemKey}
                                            />
                                            <ErrorMessage
                                              name={`rows.${itemKey}[${index}].itemName`}
                                              component='div'
                                              className={classes.errorMsg}
                                            />
                                          </div>
                                          <div className={classes.tableCell}>
                                            <Field
                                              name={`rows.${itemKey}[${index}].amount`}
                                              className={classes.textInput}
                                              type="number"
                                              placeholder="金額"
                                              step="any"
                                            />
                                            <ErrorMessage
                                              name={`rows.${itemKey}[${index}].amount`}
                                              component='div'
                                              className={classes.errorMsg}
                                            />
                                          </div>
                                          <div className={classes.funcCol}>
                                            <IconButton
                                              type="button"
                                              disabled={this.renderDeleteDisable(values, itemKey) || loading}
                                              onClick={() => {
                                                setFieldValue(`rows.${itemKey}[${index}].delete`, true)
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </div>
                                        </div>
                                        <div className={classes.tableBodyRow}>
                                          <div className={classes.dateCell} style={{ height: 40 }}>&nbsp;</div>
                                          <div className={classes.remarkCell}>
                                            <Field
                                              name={`rows.${itemKey}[${index}].remark`}
                                              className={classes.remarkTextInput}
                                              placeholder="備註"
                                            />
                                            <ErrorMessage
                                              name={`rows.${itemKey}[${index}].remark`}
                                              component='div'
                                              className={classes.errorMsg}
                                            />
                                          </div>
                                          <div className={classes.funcCol}>&nbsp;</div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className={classes.subtotalRow}>
                                  Sub-Total:
                                  <strong>  {this.renderSubTottal(values, itemKey)}</strong>
                                </div>
                              </div>
                              <div>
                                <Button
                                  size="small"
                                  startIcon={<ArrowDropDownIcon />}
                                  disabled={keyIndex === Object.keys(values).length - 1}
                                  onClick={() => sectionOnSwapDown(keyIndex)}
                                >
                                  下移
                                </Button>
                              </div>
                            </TableContainer>
                          )
                        })}
                        <Paper className={classes.totalAmountRow}>
                          <div className={classes.amountText}>{`總金額: ${this.renderTotalAmount(values)}`}</div>
                        </Paper>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>

              {noteEditVisible ? (
                <DialogContainer
                  title="編輯月結單資料"
                  open={noteEditVisible}
                  handleClose={() => this.setState({ noteEditVisible: false })}
                >
                  <DebitnoteEditForm debitNote={debitNote} saveOnPress={this.noteSaveOnPress} />
                </DialogContainer>
              ) : null}
              {sectionVisible ? (
                <DialogContainer
                  title="新增結單分段"
                  open={sectionVisible}
                  handleClose={() => this.setState({ sectionVisible: false })}
                >
                  <AddSectionForm
                    saveOnPress={title => this.setState({ sectionVisible: false }, () => addNewSection(title))}
                    value={values.rows}
                  />
                </DialogContainer>
              ) : null}
              {editSectionVisible ? (
                <DialogContainer
                  title="編輯結單分段"
                  open={editSectionVisible}
                  handleClose={() => this.setState({ editSectionVisible: false })}
                >
                  <AddSectionForm
                    saveOnPress={title => this.setState({ editSectionVisible: false }, () => editSection(title))}
                    title={values.rows[selectedItem][0].sectionName}
                    value={values.rows}
                  />
                </DialogContainer>
              ) : null}
              {deleteSectionVisible ? (
                <DialogContainer
                  title="刪取結單分段"
                  open={deleteSectionVisible}
                  handleClose={() => this.setState({ deleteSectionVisible: false })}
                  okBtnAction={() => this.setState({ deleteSectionVisible: false }, () => deleteSection())}
                >
                  確定要刪取 <strong>{values.rows[selectedItem][0].sectionName}</strong> 分段?
                </DialogContainer>
              ) : null}
            </Form>
          )
        }}
      </Formik>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(3),
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    // height: 'calc(100% + 48px)',
  },
  previewRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto'
  },
  appBar: {
    position: 'sticky',
    top: 0,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  NoteSeperator: {
    width: '100%',
    borderTop: '5px solid #000000',
    borderBottom: '5px solid #000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 24,
    fontWeight: 700,
    marginTop: theme.spacing(3),
  },
  seperator: {
    width: '100%',
    borderTop: '1px solid #aaa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 24,
    fontWeight: 700,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 650,
    marginBottom: 50,
  },
  tableHeaderRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  tableHeaderText: {
    fontSize: 24,
    fontWeight: 700,
  },
  textInput: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 500,
    border: '1px solid rgba(51,51,51, 0.4)',
  },
  remarkTextInput: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 400,
    border: '0px solid rgba(51,51,51, 0.4)',
    backgroundColor: '#fafafa'
  },
  errorMsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
  infoContainer: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  totalAmountRow: {
    width: '100%',
    padding: theme.spacing(2),
  },
  amountText: {
    fontSize: 24,
    fontWeight: 700,
    color: '#2e2e2e',
    width: '100%',
    textAlign: 'right',
  },
  links: {
    textDecoration: 'none',
    color: 'white',
  },
  infoRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 15,
  },
  infoCol: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  infoLabel: {
    fontSize: 16,
    fontWeight: 700,
    marginRight: 20,
    minWidth: 150,
  },
  infoText: {
    fontSize: 16,
    fontWeight: 400,
  },
  tealChip: {
    color: '#009688',
    borderColor: '#009688',
  },
  amberChip: {
    color: '#ffc107',
    borderColor: '#ffc107',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  TableCol: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  headerRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
    padding: theme.spacing(1),
  },
  headerCell: {
    display: 'flex',
    padding: theme.spacing(1),
    fontWeight: 700,
  },
  funcCol: {
    width: 60,
    padding: theme.spacing(0.5),
  },
  tableBody: {
    width: '100%',
  },
  dateCell: {
    width: 250,
    // padding: theme.spacing(1),
    boxSizing: 'border-box',
  },
  tableBodyCol: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderBottom: '2px dashed #ddd',
    padding: theme.spacing(1),
    marginBottom: 10,
  },
  tableBodyRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flex: 1,
    // padding: theme.spacing(1),
    marginRight: 10,
    boxSizing: 'border-box',
    // height: 61,
  },
  remarkCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    // padding: theme.spacing(1),
    marginRight: 10,
    boxSizing: 'border-box',
    flex: 2,
    height: 40,
    border: '0px'
  },
  titleH2: {
    fontSize: 24,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  editText: {
    fontSize: 14,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.4)',
    marginBottom: theme.spacing(2),
  },
  subtotalRow: {
    marginLeft: 'auto',
    padding: theme.spacing(2),
    fontSize: 16
  },
  buttonRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }
});

DebitNoteForm.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(DebitNoteForm));
