import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import HookedFormInput from '../HookedFormInput';

import { getAPropertyInformation, updateAProperty } from '../../../utils/api';

const DEFAULT_CONTACT_ITEM = {
  contactName: '',
  post: '',
  primaryPhone: '',
  secondaryPhone: '',
  fax: '',
  email: '',
  remark: '',
};

const DEFAULT_FORM_ITEM = {
  ManagementCorperation: {
    name: '',
    nameCn: '',
    remark: '',
    contacts: [],
  },
  OwnersCorperation: {
    contacts: [],
  },
};

const Schema = Yup.object().shape({
  OwnersCorperation: Yup.object().shape({
    contacts: Yup.array().of(
      Yup.object().shape({
        contactName: Yup.string()
          .nullable()
          .required('必填項目'),
        post: Yup.string().nullable(),
        primaryPhone: Yup.string().nullable(),
        secondaryPhone: Yup.string().nullable(),
        fax: Yup.string().nullable(),
        email: Yup.string()
          .nullable()
          .email('錯誤的電子郵件格式'),
        remark: Yup.string().max(400, '備註不可多於400字'),
      }),
    ),
  }),
  ManagementCorperation: Yup.object().shape({
    nameCn: Yup.string()
      .max(200, '長度不可多於200字元')
      .nullable(),
    name: Yup.string()
      .max(200, '長度不可多於200字元')
      .nullable(),
    address: Yup.string()
      .max(200, '長度不可多於200字元')
      .nullable(),
    remark: Yup.string()
      .max(400, '備註不可多於400字')
      .nullable(),
    contacts: Yup.array().of(
      Yup.object().shape({
        contactName: Yup.string()
          .nullable()
          .required('必填項目'),
        post: Yup.string().nullable(),
        primaryPhone: Yup.string().nullable(),
        secondaryPhone: Yup.string().nullable(),
        fax: Yup.string().nullable(),
        email: Yup.string()
          .nullable()
          .email('錯誤的電子郵件格式'),
        remark: Yup.string().max(400, '備註不可多於400字'),
      }),
    ),
  }),
});

class PropertyOwnerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { ...DEFAULT_FORM_ITEM },
      loading: false,
      formLoading: true,
    };
  }

  componentDidMount() {
    this.intiEditForm();
  }

  async intiEditForm() {
    const { match } = this.props;
    const { params } = match;
    const { propertyid } = params;
    try {
      const propertyRes = await getAPropertyInformation(propertyid);
      const { ManagementCorperation, OwnersCorperation } = propertyRes.data;
      const form = {
        OwnersCorperation:
          OwnersCorperation || DEFAULT_FORM_ITEM.OwnersCorperation,
        ManagementCorperation:
          ManagementCorperation || DEFAULT_FORM_ITEM.ManagementCorperation,
      };
      this.setState({
        form,
        formLoading: false,
      });
    } catch (err) {
      toast('網絡異常，請重試。');
      this.setState({ formLoading: false });
    }
  }

  renderEditDisable(values) {
    const { form } = this.state;
    const same = _.isEqual(values, form);
    if (same) {
      return true;
    }
    return false;
  }

  async onSubmit(values) {
    this.submitEditProperty(values);
  }

  async submitEditProperty(values) {
    const { history, match } = this.props;
    const { params } = match;
    const { propertyid } = params;
    this.setState({ loading: true });
    try {
      await updateAProperty(propertyid, values);
      toast('成功更新');
      history.goBack();
    } catch (err) {
      console.log(err);
      toast('網絡異常，請重試。');
      this.setState({ loading: false });
    }
  }

  render() {
    const { classes } = this.props;
    const { form, loading, formLoading } = this.state;
    if (formLoading) {
      return (
        <div className={classes.root}>
          <CircularProgress size={30} color="primary" />
        </div>
      );
    }
    return (
      <Formik
        initialValues={form}
        validationSchema={Schema}
        onSubmit={values => this.onSubmit(values)}
      >
        {({ values }) => (
          <Form className={classes.root}>
            <Paper className={classes.formContainer}>
              <div className={classes.formWrapper}>
                <h2>編輯法團或管理公司</h2>
                <div className={classes.sectionTitle}>管理公司</div>
                <HookedFormInput
                  name="ManagementCorperation.nameCn"
                  label="管理公司名稱"
                  width="100%"
                />
                <HookedFormInput
                  name="ManagementCorperation.name"
                  label="管理公司名稱 (英文)"
                  width="100%"
                />
                <HookedFormInput
                  name="ManagementCorperation.address"
                  label="管理公司地址"
                  width="100%"
                />
                <HookedFormInput
                  name="ManagementCorperation.remark"
                  label="備註"
                  width="100%"
                />
                <div className={classes.label}>管理公司聯絡人</div>
                <FieldArray
                  name="ManagementCorperation.contacts"
                  validateOnChange={false}
                  render={arrayHelpers => (
                    <div style={{ width: '100%' }}>
                      {values.ManagementCorperation.contacts.map(
                        (item, index) => (
                          <div className={classes.contactContainer}>
                            <div className={classes.contactContainerHeader}>
                              <div
                                className={classes.contactContainerTitle}
                              >{`管理公司聯絡人 ${index + 1}`}</div>
                              <IconButton
                                size="small"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                            <HookedFormInput
                              name={`ManagementCorperation.contacts.${index}.contactName`}
                              label="姓名"
                              width="100%"
                            />
                            <HookedFormInput
                              name={`ManagementCorperation.contacts.${index}.post`}
                              label="職位"
                              width="100%"
                            />
                            <HookedFormInput
                              name={`ManagementCorperation.contacts.${index}.primaryPhone`}
                              label="主要電話"
                              width="100%"
                            />
                            <HookedFormInput
                              name={`ManagementCorperation.contacts.${index}.secondaryPhone`}
                              label="備用電話"
                              width="100%"
                            />
                            <HookedFormInput
                              name={`ManagementCorperation.contacts.${index}.fax`}
                              label="Fax"
                              width="100%"
                            />
                            <HookedFormInput
                              name={`ManagementCorperation.contacts.${index}.email`}
                              label="Email"
                              width="100%"
                            />
                            <HookedFormInput
                              name={`ManagementCorperation.contacts.${index}.remark`}
                              label="備註"
                              width="100%"
                            />
                          </div>
                        ),
                      )}
                      <Button
                        color="secondary"
                        variant="contained"
                        type="button"
                        onClick={() => {
                          arrayHelpers.push({ ...DEFAULT_CONTACT_ITEM });
                        }}
                      >
                        新增管理公司聯絡人
                      </Button>
                    </div>
                  )}
                />
                <div className={classes.seperator} />
                <div className={classes.sectionTitle}>法團</div>
                <div className={classes.label}>法團聯絡人</div>
                <FieldArray
                  name="OwnersCorperation.contacts"
                  validateOnChange={false}
                  render={arrayHelpers => (
                    <div style={{ width: '100%' }}>
                      {values.OwnersCorperation.contacts.map((item, index) => (
                        <div className={classes.contactContainer}>
                          <div className={classes.contactContainerHeader}>
                            <div
                              className={classes.contactContainerTitle}
                            >{`法團聯絡人 ${index + 1}`}</div>
                            <IconButton
                              size="small"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                          <HookedFormInput
                            name={`OwnersCorperation.contacts.${index}.contactName`}
                            label="姓名"
                            width="100%"
                          />
                          <HookedFormInput
                            name={`OwnersCorperation.contacts.${index}.post`}
                            label="職位"
                            width="100%"
                          />
                          <HookedFormInput
                            name={`OwnersCorperation.contacts.${index}.primaryPhone`}
                            label="主要電話"
                            width="100%"
                          />
                          <HookedFormInput
                            name={`OwnersCorperation.contacts.${index}.secondaryPhone`}
                            label="備用電話"
                            width="100%"
                          />
                          <HookedFormInput
                            name={`OwnersCorperation.contacts.${index}.fax`}
                            label="Fax"
                            width="100%"
                          />
                          <HookedFormInput
                            name={`OwnersCorperation.contacts.${index}.email`}
                            label="Email"
                            width="100%"
                          />
                          <HookedFormInput
                            name={`OwnersCorperation.contacts.${index}.remark`}
                            label="備註"
                            width="100%"
                          />
                        </div>
                      ))}
                      <Button
                        color="secondary"
                        variant="contained"
                        type="button"
                        onClick={() => {
                          arrayHelpers.push({ ...DEFAULT_CONTACT_ITEM });
                        }}
                      >
                        新增法團聯絡人
                      </Button>
                    </div>
                  )}
                />
                <Button
                  type="submit"
                  style={{ width: '100%', marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={loading || this.renderEditDisable(values)}
                >
                  <div>
                    {loading ? (
                      <CircularProgress color="primary" size={22} />
                    ) : (
                      '提交'
                    )}
                  </div>
                </Button>
              </div>
            </Paper>
          </Form>
        )}
      </Formik>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'Column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 500,
    // height: 500,
    margin: 20,
  },
  seperator: {
    width: '100%',
    height: 3,
    backgroundColor: '#eeeee',
    marginTop: 20,
  },
  contactContainer: {
    border: '2px solid #eeeeee',
    borderRadius: 10,
    padding: 12,
    marginBottom: 20,
  },
  contactContainerTitle: {
    fontWeight: '500',
    fontSize: 18,
  },
  contactContainerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  sectionTitle: {
    fontWeight: '500',
    fontSize: 22,
    alignSelf: 'flex-start',
    marginBottom: 20,
    marginTop: 10,
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
    alignSelf: 'flex-start',
  },
});

PropertyOwnerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PropertyOwnerForm));
