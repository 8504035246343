import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import BuildIcon from '@material-ui/icons/Build';
import HouseIcon from '@material-ui/icons/House';
import DescriptionIcon from '@material-ui/icons/Description';
import HistoryIcon from '@material-ui/icons/History';

import MenuItemButton from './MenuItemButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    margin: '0px 0px',
  },
  menuItem: {
    width: '100%',
    padding: '8px 16px',
  },
  links: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
  aciveLinks: {
    borderRight: '4px solid #3f51b5',
    backgroundColor: 'rgba(63, 81, 181, 0.1)',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'cener',
    justifyContent: 'flex-start',
  },
}));

function MenuItems() {
  const style = useStyles();
  return (
    <ul className={style.root}>
      <NavLink
        to="/"
        exact
        className={style.links}
        activeClassName={style.aciveLinks}
      >
        <MenuItemButton
          icon={
            <DashboardIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="儀表板"
        />
      </NavLink>
      <NavLink
        to="/staff"
        className={style.links}
        activeClassName={style.aciveLinks}
      >
        <MenuItemButton
          icon={
            <PeopleIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="員工管理"
        />
      </NavLink>
      <NavLink
        to="/companies"
        className={style.links}
        activeClassName={style.aciveLinks}
      >
        <MenuItemButton
          icon={
            <LocationCityIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="公司管理"
        />
      </NavLink>
      <NavLink
        to="/properties"
        className={style.links}
        activeClassName={style.aciveLinks}
      >
        <MenuItemButton
          icon={
            <HouseIcon style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }} />
          }
          label="物業管理"
        />
      </NavLink>
      <NavLink
        to="/debitNotes"
        exact
        className={style.links}
        activeClassName={style.aciveLinks}
      >
        <MenuItemButton
          icon={
            <DescriptionIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="月結單管理"
        />
      </NavLink>
      <NavLink
        to="/maintenance"
        exact
        className={style.links}
        activeClassName={style.aciveLinks}
      >
        <MenuItemButton
          icon={
            <BuildIcon style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }} />
          }
          label="維護管理"
        />
      </NavLink>
      <NavLink
        to="/history"
        exact
        className={style.links}
        activeClassName={style.aciveLinks}
      >
        <MenuItemButton
          icon={
            <HistoryIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="員工歷史"
        />
      </NavLink>
    </ul>
  );
}

export default MenuItems;
