/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Tenant from './Tenant/Loadable';
import PropertyForm from './PropertyForm/Loadable';

function index() {
  return (
    <Switch>
      <Route exact path="/Tenant" component={Tenant} />
      <Route exact path="/properties/new" component={PropertyForm} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default index;
