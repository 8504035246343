import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

import PropertyOwnerForm from '../../../components/Forms/PropertyOwnerForm';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
}));

function PropertyOwnerFormPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>編輯法團或管理公司</title>
      </Helmet>
      <div className={classes.paper}>
        <PropertyOwnerForm />
      </div>
    </div>
  );
}

export default PropertyOwnerFormPage;
