/* eslint-disable indent */
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import DatePicker from 'react-datepicker';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { zhTW } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { useField, useFormikContext, ErrorMessage } from 'formik';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%',
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      outline: 'none',
    },
    padding: '10px 18px',
    borderRadius: 6,
    // boxSizing: 'border-box',
    fontSize: 14,
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  root: {
    height: 95,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  wrapperStyle: {
    width: '100%',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
}));

function DatePickerInput({
  label,
  name,
  minDate,
  disabled,
  onChangeCallback,
  isClearable,
  dateFormat,
  containerStyle,
  ...props
}) {
  const classes = useStyles();
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField({ name });
  return (
    <div className={classes.root} style={containerStyle}>
      <div className={classes.label}>{label}</div>
      <DatePicker
        wrapperClassName={classes.wrapperStyle}
        dateFormat={dateFormat || 'dd/MM/yyyy'}
        {...field}
        {...props}
        selected={field.value || null}
        onChange={value => {
          setFieldValue(field.name, value);
          setFieldTouched(field.name, true);
          onChangeCallback(value);
        }}
        locale={zhTW}
        customInput={<BootstrapInput />}
        disabled={disabled}
        minDate={minDate}
        onChangeRaw={
          isClearable
            ? () => {}
            : e => {
                e.preventDefault();
                return false;
              }
        }
      />
      <ErrorMessage name={name} component="div" className={classes.errmsg} />
    </div>
  );
}

DatePickerInput.defaultProps = {
  onChangeCallback: () => {},
  disabled: false,
  isClearable: false,
};

DatePickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minDate: PropTypes.object,
  onChangeCallback: PropTypes.func,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  dateFormat: PropTypes.string,
  containerStyle: PropTypes.object,
};

export default DatePickerInput;
