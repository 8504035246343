/* eslint-disable no-param-reassign */
import moment from 'moment';
// import _ from 'lodash';
import { TABLE_PAGE_SIZE } from '../constant';

export const convertToKB = size => {
  if (size) {
    const KB = Math.floor(size / 1000);
    return KB.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return 0;
};

export const numberWithCommas = number => {
  if (number && typeof number === 'number') {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  if (number) {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return 0;
};

export const renderPageCount = totalCount => {
  const NOPage = totalCount / TABLE_PAGE_SIZE;
  if (NOPage > 0) {
    return Math.ceil(NOPage);
  }
  return 1;
};

export const renderRegion = region => {
  switch (region) {
    case 'kowloon':
      return '九龍';
    case 'newterritory':
      return '新界';
    default:
      return '香港';
  }
};

export const getCurrentRentalPrice = (rentalPeriod, date) => {
  if (!rentalPeriod) {
    return 0;
  }
  for (let x = 0; x < rentalPeriod.length; x += 1) {
    const { rent, startDate, endDate } = rentalPeriod[x];
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).endOf('day');
    if (moment(date || new Date()).isBetween(start, end)) {
      return rent;
    }
  }
  return 0;
};

export const renderContactString = contact => {
  let string = '';
  if (contact.mobile) {
    string += `主要: ${contact.mobile}`;
  }
  if (contact.office) {
    string += `  /  備用: ${contact.office}`;
  }
  if (contact.fax) {
    string += `  /  Fax: ${contact.fax}`;
  }
  if (contact.email) {
    string += `  /  Email: ${contact.email}`;
  }
  return string;
};

export const renderContactType = type => {
  switch (type) {
    case 'debitnote':
      return '主要聯絡人 (將顯示於月結單上)';
    case 'other':
      return '其他聯絡人';
    case 'maintenance':
      return '維修';
    default:
      return '其他聯絡人';
  }
};

export const renderPropertyAddress = item => {
  let address = '';
  if (item.region) {
    address += renderRegion(item.region);
  }
  if (item.district) {
    address += ` ${item.district}`;
  }
  if (item.address) {
    address += ` ${item.addressCn ? item.addressCn : item.address}`;
  }
  if (item.buildingName) {
    address += ` ${item.buildingName}`;
  }
  if (item.shop) {
    address += ` ${item.shop}`;
  }
  return address;
};

export const formatDebitNoteRow = (rowsArray, debitNote) => {
  const temp = [...rowsArray];
  const currentMonth = parseInt(moment(debitNote.billingDate).format('M'), 10);
  const from = {
    current: [],
    overdue1: [],
    overdue2: [],
    others: [],
  };
  temp.forEach(row => {
    row.focus = null;
    const month = parseInt(moment(row.startDate).format('M'), 10);
    if (month === currentMonth) {
      from.current.push(row);
    } else if (currentMonth - month === 1) {
      from.overdue1.push(row);
    } else if (currentMonth - month === 2) {
      from.overdue2.push(row);
    } else {
      from.others.push(row);
    }
  });
  return from;
};

export const getFileType = file => {
  if (file) {
    const temp = file.split('.');
    if (temp[1] === 'pdf') {
      return 'pdf';
    }
    if (
      temp[1] === 'jpg' ||
      temp[1] === 'png' ||
      temp[1] === 'jpeg' ||
      temp[1] === 'PNG' ||
      temp[1] === 'JPG' ||
      temp[1] === 'JPEG'
    ) {
      return 'image';
    }
    return '';
  }
  return '';
};

export const formatPropertyList = propertyArray => {
  const temp = [];
  propertyArray.forEach(property => {
    let addressLabel = '';
    addressLabel += property.streetName ? `${property.streetName} ` : '';
    addressLabel += property.buildingName ? `${property.buildingName} ` : '';
    addressLabel += property.shop ? `${property.shop} ` : '';
    temp.push({
      propertyid: property.propertyid,
      propertyAddress: property.address,
      propertyName: addressLabel,
      name: addressLabel,
      address: property.address,
    });
  });
  return temp;
};

export const removeEmptyItemInObject = object => {
  const temp = { ...object };
  Object.keys(object).forEach(key => {
    if (!temp[key]) {
      delete temp[key];
    }
  });
  return temp;
};

export const removeStringNewLines = (str, sep) => {
  if (str) {
    return str.replace(/(\r\n|\n|\r)/gm, sep || '');
  }
  return '';
};
