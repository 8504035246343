/* eslint-disable func-names */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import SectionTitleAutoComplete from '../SectionTitleAutoComplete';

const ITEMS = [
  { value: 'Overdue Amount', title: 'Overdue Amount' },
  { value: 'Brought Forward Amount', title: 'Brought Forward Amount' },
];

const FORM_ITEM = {
  title: '',
};

class AddSectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { ...FORM_ITEM },
      formLoading: true,
    };
  }

  componentDidMount() {
    const { title } = this.props;
    const temp = title || '';
    this.setState({ form: { title: temp }, formLoading: false });
  }

  async onSubmit(values) {
    const { saveOnPress } = this.props;
    const { title } = values;
    saveOnPress(title);
  }

  render() {
    const { classes, value } = this.props;
    const { form, formLoading } = this.state;
    let rows = [];
    Object.keys(value).forEach(key => {
      rows = rows.concat(value[key]);
    });
    const sectionNames = [...new Set(rows.map(row => row.sectionName))];
    const Schema = Yup.object().shape({
      title: Yup.string()
        .test('reserved-title', 'Current Amount 不能使用', function(name) {
          return name !== 'Current Amount';
        })
        .test('repeat-title', '分段標題不能重用', function(name) {
          if (sectionNames.includes(name)) {
            return false;
          }
          return true;
        })
        .max(30, '不可多於30個字母')
        .required('必填項目'),
    });
    if (formLoading) {
      return (
        <div className={classes.root}>
          <CircularProgress size={30} color="primary" />
        </div>
      );
    }
    return (
      <Formik
        initialValues={form}
        validationSchema={Schema}
        onSubmit={values => this.onSubmit(values)}
      >
        {({ values, handleBlur, touched, errors, setFieldValue }) => (
          <Form className={classes.root}>
            <div className={classes.formContainer}>
              <SectionTitleAutoComplete
                values={values}
                options={ITEMS}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
              <Button
                type="submit"
                style={{ width: '100%', marginTop: 20 }}
                variant="contained"
                color="primary"
              >
                提交
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'Column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  formContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
  },
  label: {
    width: '100%',
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 500,
    // height: 500,
    margin: 20,
  },
  fieldCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerTextRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginBottom: 20,
    marginTop: 10,
    width: 640,
  },
  bubbleWrapper: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: '#ddd',
  },
  bubbleText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
  },
  headerText: {
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginLeft: 20,
  },
  addressCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  addressLink: {
    fontSize: 14,
    color: '#2e2e2e',
    fontWeight: 700,
  },
  addressTime: {
    fontSize: 16,
    color: 'rgba(51,51,51,0.6)',
    fontWeight: 500,
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    backgroundColor: '#2478FF',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 20,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(36, 120, 255, 0.8)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    width: '100%',
    backgroundColor: '#e74c3c',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 24,
    height: 48,
    '&:hover': {
      backgroundColor: '#c0392b',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  btn: {
    width: '100%',
  },
});

AddSectionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  saveOnPress: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(AddSectionForm));
